@import 'styles/breakpoints';

.card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  padding: var(--spacing-600);
  border-radius: var(--radius-sm);
  color: var(--text-default);

  &.compact {
    padding: var(--spacing-500);
  }
}

// Colors
.primary {
  background-color: var(--accent-brand);
}

.neutral {
  background-color: var(--bg-layer);
}

.yellow {
  background-color: var(--accent-yellow);
}

.pink {
  background-color: var(--accent-pink);
}

.accent-lightblue {
  background: var(--accent-lightblue);
}

.accent-lightblue-subdued {
  background: var(--accent-lightblue-subdued);
}
