// REACT DATEPICKER STYLES
// should be global styles because they overwrite styling provided by react-datepicker package,
// and can't be imported from a CSS module

.react-datepicker__header {
  background-color: var(--white);
  border-bottom: 1px solid #d8d8d8;
}

.react-datepicker__current-month {
  color: var(--text-default);
  font-family: var(--font-family);
  font-weight: normal;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: var(--text-default);
}

.react-datepicker__day--disabled {
  color: var(--neutral-400);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: var(--blue-800);
  color: var(--white);
  &:hover {
    background-color: var(--bg-primary);
    color: var(--text-default);
  }
}

.react-datepicker__year-read-view--down-arrow {
  top: 4px;
}
