.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.fade-in-exit-active {
  opacity: 0;
  transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.fade-in-slide-up-enter {
  opacity: 0;
  transform: scale(0.99) translateY(20px);
}

.fade-in-slide-up-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.fade-in-slide-up-exit-active {
  opacity: 0;
  transform: scale(0.99) translateY(20px);
  transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
}

.slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
}

.slide-up-enter {
  transform: translateY(100%);
}

.slide-up-enter-active {
  transform: translateY(0%);
  transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
}

.slide-up-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
}
