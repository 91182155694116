.form-row {
  display: block;
  margin-bottom: var(--spacing-500);

  &:last-child,
  &.margin-bottom-none {
    margin-bottom: 0;
  }

  .form-input {
    margin-bottom: var(--spacing-500);

    input,
    select,
    textarea {
      width: 100%;
    }

    label {
      display: block;
      margin-bottom: var(--spacing-100);
    }
  }

  @media (min-width: $widthMobile) and (max-width: $widthTablet),
  (min-width: $widthDesktop) {
    &.is-2 {
      display: flex;
      gap: var(--spacing-500);

      .form-input {
        margin-bottom: 0;
      }

      .form-input,
      .form-row {
        width: 50%;
      }
    }
  }
}

.form-group {
  width: 100%;
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: var(--spacing-100);
  }
}

input {
  font-family: inherit;
}

fieldset {
  border: unset;
  padding: 0;
  margin: 0;
}

input[type='text'],
input[type='phone'],
input[type='date'],
input[type='tel'],
input[type='email'],
input[type='number'],
select,
textarea {
  font-family: inherit;
  font-size: var(--text-sm-font-size);
  line-height: normal;
  border-radius: var(--radius-sm);
  padding: var(--spacing-300) var(--spacing-400);
  background-color: var(--white);
  box-sizing: border-box;
  color: var(--text-default);
  border: 1px solid var(--border-default);
  outline: none;
  transition: all var(--transition-speed);
  width: 100%;

  @include from($widthTablet) {
    font-size: inherit;
  }

  &::placeholder {
    color: var(--neutral-500);
  }

  &:focus {
    border-color: var(--border-action);
  }

  &.has-error {
    border-color: var(--text-negative);
    background-color: var(--bg-negative);

    &.border:focus {
      border-color: var(--bg-critical);
    }
  }

  &:read-only:not(select),
  &:disabled {
    background-color: var(--border-default);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

textarea {
  padding: var(--spacing-300);
  height: auto;
}

input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.append-input {
  position: relative;

  input {
    padding-right: 60px;
  }

  .append {
    font-size: inherit;
    line-height: 44px;
    position: absolute;
    right: var(--spacing-400);
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }
}

select {
  font-family: inherit;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 3.385L1.18.148a.746.746 0 0 0-1.028.13.702.702 0 0 0 .134 1.002l4.767 3.572c.263.197.63.197.894 0l4.767-3.572A.702.702 0 0 0 10.848.28.746.746 0 0 0 9.82.149L5.5 3.384z' fill='%23142B68' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right var(--spacing-200) center;
}

select::-ms-expand {
  display: none;
}

label input[type='checkbox'],
label input[type='radio'] {
  accent-color: #0fa282;
  margin-right: var(--spacing-100);
}

.upload {
  margin-top: var(--spacing-400);
  display: flex;
  flex-direction: column;

  .info {
    margin-left: var(--spacing-100);
    color: var(--text-default);

    svg {
      height: 13px;
      vertical-align: middle;
    }
  }

  .file {
    display: flex;
    align-items: center;
    gap: var(--spacing-400);

    @include until($widthMobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-300);
    }

    label.label-as-button {
      background-color: #f7f7f7;
      border: 1px solid #dddede;
      border-radius: 30px;
      display: inline-flex;
      cursor: pointer;
      touch-action: manipulation;
      padding: var(--spacing-200) 20px;
      font-weight: 400;
      margin-bottom: 0;
      flex-shrink: 0;
    }

    .filename {
      font-weight: 400;
      font-size: var(--text-sm-font-size);
      font-style: italic;
    }

    .button-delete {
      font-size: var(--text-sm-font-size);
      line-height: var(--text-sm-line-height);
      text-decoration: underline;
      cursor: pointer;
      color: var(--text-primary);
    }
  }
}
