@import 'styles/breakpoints';

.footer {
  a {
    color: var(--text-default);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.extra-space {
    padding-bottom: 160px;
  }
}

.vacancies {
  display: flex;
  align-items: center;
}

.vacancies-notification {
  border-radius: var(--radius-sm);
  background-color: var(--bg-primary);
  color: var(--white);
  font-size: 8px;
  font-weight: 700;
  padding: 3px;
  margin-left: var(--spacing-100);
  line-height: 1;
}

.top {
  background-color: var(--accent-brand-subdued);
  padding-top: var(--spacing-700);
  padding-bottom: var(--spacing-500);
}

.logo-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo {
  height: 35px;
  width: 130px;
  color: var(--text-default);
}

.socials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-link {
  margin-right: var(--spacing-500);
  fill: currentColor;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    .icon {
      color: var(--text-primary);
    }
  }

  .icon {
    color: var(--text-default);
    height: 22px;
    width: 22px;
  }
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-500);
  margin-top: var(--spacing-700);
  margin-bottom: var(--spacing-500);

  @include from($widthTablet) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.menu-section {
  margin-bottom: var(--spacing-500);

  &:last-child {
    margin-bottom: 0;
  }

  ul {
    li {
      margin-bottom: var(--spacing-100);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list-title {
    font-size: var(--text-sm-font-size);
    line-height: var(--text-sm-line-height);
    font-weight: bold;
    margin-bottom: var(--spacing-400);
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: var(--text-xs-font-size);

  @include until($widthTablet) {
    display: block;
    text-align: center;
  }
}

.bottom-menu {
  @include until($widthTablet) {
    margin-top: var(--spacing-400);
  }

  li {
    display: inline-block;
    margin-right: var(--spacing-500);

    @include until($widthTablet) {
      display: block;
      margin-right: 0;
      margin-bottom: var(--spacing-400);
    }

    &:last-child {
      margin-right: 0;

      @include until($widthTablet) {
        margin-bottom: 0;
      }
    }

    a {
      @include until($widthTablet) {
        text-decoration: underline;
      }
    }
  }
}
