// COLOR UTILS

.is-grey {
  color: var(--neutral-400);
}

.is-green:not(section) {
  color: var(--text-primary);
}

.is-orange {
  color: var(--accent-orange);
}

.is-red {
  color: var(--text-negative);
}

.is-yellow {
  color: var(--on-yellow-subdued);
}

.is-blue {
  color: var(--text-default);
}

// DISPLAY UTILS

.is-block {
  display: block;
}

.is-inline-block {
  display: inline-block;
}

// TEXT UTILS

.has-text-centered {
  text-align: center;
}

.has-text-right {
  text-align: right;
}

.is-capitalised {
  text-transform: capitalize;
}

.is-lowercase {
  text-transform: lowercase;
}

.is-underlined {
  text-decoration: underline;
}

.is-clickable {
  cursor: pointer;
}

.is-link {
  color: var(--text-primary);

  &:hover {
    text-decoration: underline;
  }
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-error-text {
  color: var(--text-negative);
  margin-top: var(--spacing-200);
  margin-bottom: 0;
}

// FLEX UTILS

.is-flex-column {
  display: flex;
  flex-direction: column;
}

.is-flex-centered {
  align-items: center;
}

.is-flex-row {
  display: flex;
  flex-direction: row;
}

.is-flex-row-reversed {
  display: flex;
  flex-direction: row-reverse;
}

.is-flex-centered-direction {
  justify-content: center;
}

.is-flex-end {
  display: flex;
  justify-content: flex-end;
}

.is-flex-spread {
  display: flex;
  justify-content: space-between;
}

.is-flex-remaining {
  flex-grow: 1;
}

.is-flex-wrap {
  flex-wrap: wrap;
}

.is-flex-column-mobile {
  @include until($widthMobile) {
    display: flex;
    flex-direction: column;
  }
}

// WIDTH UTILS

.is-fullwidth {
  display: block;
  width: 100%;
}
