@import 'styles/breakpoints';

@mixin header-theme($background-color, $text-color, $text-hover-color, $border-color) {
  background-color: $background-color;
  color: $text-color;
  border-bottom: 1px solid $border-color;

  .nav {
    a, button {
      color: $text-color;

      &:hover {
        cursor: pointer;
        color: $text-hover-color;
      }
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $text-color;
  }
}

.header {
  z-index: 7;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid transparent;
  transition: all var(--transition-speed);

  @include header-theme(var(--white), var(--text-default), var(--text-primary), rgba(123, 120, 139, 0.26));

  @include until($header-break-point) {
    height: inherit;
  }

  &.scrolling-down {
    transform: translateY(-100%);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include until($widthDesktop) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include until($header-break-point) {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 70px auto;
      justify-content: flex-start;
      align-items: stretch;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .menu {
    flex-grow: 3;
    display: flex;
    gap: var(--spacing-400);
    align-items: center;
    height: var(--header-height);
    position: relative;

    @include until($header-break-point) {
      justify-content: flex-start;
      flex-grow: 0;
      padding-left: var(--spacing-200);
      padding-right: var(--spacing-200);
    }
  }

  .badge-link {
    text-decoration: none;
  }

  .logo {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      width: 80px;
      height: auto;
      position: relative;
    }

    @include until($header-break-point) {
      margin-top: 3px;
    }
  }

  .language-switcher-mobile {
    display: none;
    margin-top: var(--spacing-200);
  }

  .hamburger-container {
    display: none;
    align-items: center;
    color: var(--text-default);
    transition: all 0.2s ease-in-out;
    opacity: 0.8;
    cursor: pointer;
    margin-left: auto;
    padding: 0;

    @include until($header-break-point) {
      display: flex;
    }

    .hamburger-label {
      text-transform: uppercase;
      font-weight: 700;
    }

    .hamburger {
      padding: var(--spacing-100) var(--spacing-200);
      opacity: 0.9;

      .bar {
        display: block;
        width: 30px;
        height: 2px;
        margin: 6px auto;
        transition: all 0.2s ease-in-out;
        background-color: var(--blue-800);

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.active {
        .bar {
          background-color: var(--blue-800);

          &:nth-child(1) {
            transform: translateY(var(--spacing-200)) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }
    }
  }

  .nav,
  .mobile-nav {
    @include until($header-break-point) {
      padding-left: 20px;
      padding-right: 20px;
    }

    ul {
      li {
        position: relative;

        &.dropdown-trigger {
          &:hover {
            .main-nav-item {
              color: var(--text-primary);

              > svg {
                transform: rotate(180deg);
              }
            }
          }

          a, button {
            display: flex;
            align-items: center;
            padding-right: var(--spacing-200);
            text-decoration: none;
          }
        }

        .dropdown {
          position: absolute;
          top: var(--header-height);
          left: 50%;
          transform: translateX(-50%);
          background-color: var(--white);
          border-bottom-left-radius: var(--radius-md);
          border-bottom-right-radius: var(--radius-md);
          border: 1px solid var(--border-default);
          border-top: none;
          padding: var(--spacing-200) 0;
          box-shadow: rgba(0, 0, 0, 0.15) 0 12px 16px;
          transition: var(--transition-speed);
          opacity: 0;
          visibility: hidden;
          flex-direction: column;
          width: max-content;

          &[data-open='true'] {
            opacity: 1;
            visibility: visible;
          }

            li a {
              line-height: 1;
              padding: var(--spacing-200) var(--spacing-400);
              color: var(--text-default);
              text-shadow: none;

              &:hover {
                color: var(--text-primary);
              }
            }
        }
      }
    }
  }

  .nav {
    ul {
      display: flex;
      flex-direction: row;

      li {
        a, button {
          line-height: var(--header-height);
          padding: 0 var(--spacing-400);

          &.active {
            font-weight: 700;
          }

          &.highlighted {
            position: relative;

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: calc(50% - 0.5rem);
              right: 0;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              border-radius: var(--radius-sm);
              background-color: var(--accent-orange);
            }
          }
        }

        @include until(1220px) {
          a, button {
            padding: 0 var(--spacing-200);
          }
        }
      }
    }
  }

  .mobile-nav {
    display: none;

    ul {
      li {
        list-style: none;

        .main-nav-item {
          display: block;
          text-decoration: none;
          padding: var(--spacing-300) 0;
          color: var(--text-default);
        }
      }

      li:first-child {
        .main-nav-item {
          padding-left: 0;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    .cta {
      margin-left: var(--spacing-400);

      @include until($header-break-point) {
        display: none;
      }

      .cta-button {
        text-shadow: none;
      }

      .portal-button {
        border: 2px solid var(--border-high-contrast);
        background: transparent;
        color: var(--text-default);
        border-radius: var(--radius-sm);
        margin-left: var(--spacing-400);
        padding: var(--spacing-200);
        text-align: center;
        font-weight: 700;
        text-shadow: none;
        transition: 0.15s;
        text-decoration: none;

        @include until($widthMobile) {
          display: inline-block;
          width: 100%;
          margin-top: var(--spacing-200);
          margin-left: 0;
          padding: var(--spacing-200) 0;
        }

        &:hover {
          box-shadow: 0 0 4px 0.8px var(--blue-800);
        }

        svg {
          margin-bottom: -7px;
          margin-right: 6px;
        }
      }
    }

    @include until($header-break-point) {
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }

  @include until($header-break-point) {
    &.with-menu-open {
      overflow: hidden;
      height: 100dvh;

      &.scrolling-down {
        transform: translateY(0);
      }

      @include header-theme(var(--white), var(--text-default), var(--text-primary), rgba(123, 120, 139, 0.26));

      .logo {
        svg.image {
          color: var(--text-default);
        }
      }

      .content {
        padding-bottom: var(--spacing-400);
        gap: var(--spacing-400);
      }

      .cta {
        display: block;
        margin-left: 0;
        margin-top: auto;
        padding-left: 20px;
        padding-right: 20px;
      }

      .language-switcher-mobile {
        display: block;
      }

      .mobile-nav {
        display: block;

        ul {
          li.dropdown-trigger {
            .main-nav-item {
              svg {
                transform: none;
              }

              &[aria-expanded='true'] {
                svg {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .dropdown {
          position: static;
          background-color: transparent;
          transform: none;
          border-radius: 0;
          box-shadow: none;
          border: 0;
          border-left: 1px solid rgba(16, 0, 97, 0.2);
          visibility: visible;
          opacity: 1;
          transition: 0s;
          padding: 0;
          margin-bottom: var(--spacing-200);
          display: none;

          &[data-open='true'] {
            display: block;
          }
        }
      }
    }
  }
}

.is-home {
  @include header-theme(transparent, var(--white), var(--text-primary), transparent);

  @include from($widthDesktop) {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  }

  &:not(.is-scrolling):not(.with-menu-open) {
    .logo {
      svg.image {
        color: var(--white);
      }
    }

    .nav {
      ul {
        li {
          .dropdown {
            top: calc(var(--header-height) - var(--spacing-200));
            border-top-left-radius: var(--radius-md);
            border-top-right-radius: var(--radius-md);
          }
        }
      }
    }

    .hamburger-container {
      color: var(--white);
    }

    .hamburger {
      .bar {
        background-color: var(--white);
      }

      &.active {
        .bar {
          background-color: var(--blue-800);
        }
      }
    }
  }

  @include until($header-break-point) {
    &.with-menu-open {
      .nav {
        ul {
          li {
            &:not(.is-scrolling) {
              .dropdown {
                top: 0;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &.is-scrolling {
    @include header-theme(var(--white), var(--text-default), var(--text-primary), rgba(123, 120, 139, 0.26));
    text-shadow: none;
  }
}
