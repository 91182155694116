html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: var(--font-family);
  color: var(--text-default);
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  color: var(--text-default);
  font-family: var(--font-family);
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  hyphens: manual;
}

h2 {
  span,
  mark {
    color: var(--text-default);
    position: relative;
    background-color: transparent;

    &:after {
      content: '';
      background-color: var(--accent-yellow);
      position: absolute;
      top: 60%;
      right: calc(var(--spacing-100) * -1);
      bottom: 0;
      left: calc(var(--spacing-100) * -1);
      z-index: -1;

      @include until($widthMobile) {
        display: none;
      }
    }
  }
}

button {
  border: none;
  background-color: transparent;
  font-family: var(--font-family);
  font-weight: normal;
  margin: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

label {
  font-family: var(--font-family);
  color: var(--text-default);
  font-weight: 600;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

p {
  &.is-color-text {
    color: var(--text-default);
  }
}

figure {
  margin: 0;
}

iframe {
  border: none;
}
