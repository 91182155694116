@import 'styles/breakpoints';

.content {
  gap: var(--spacing-400);
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.submit {
  margin-top: auto;
}
