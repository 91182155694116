@import 'breakpoints';

:root {
  --header-height: 70px;
  --box-shadow-highlight: 0 0 15px 0 rgba(71, 250, 193, 0.5);
  --transition-speed: 195ms;
  --height-hero-content-page: 460px;
  --height-hero-small-content-page: 230px;
  --quiz-card-height: 600px;

  // Will be replaced with --background-default from DS once the color code is changed there to vanilla
  --vanilla: #FCFBEC;

  @include from($widthTablet) {
    --quiz-card-height: 500px;
  }

  @include from($widthDesktop) {
    --quiz-card-height: 400px;
  }
}
