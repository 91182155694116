@import 'styles/breakpoints';

.reset-button {
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;

  @include until($widthDesktop) {
    bottom: 60px;
    margin: 0 10px;
  }

  .button-card {
    width: 208px;
    background-color: var(--bg-default);
    display: flex;
    flex-direction: column;
    padding: var(--spacing-200);
    gap: var(--spacing-200);
    position: absolute; // Make the button-card position absolute
    top: 0; // Position it at the top
    left: 100px; // Position it at the left
    transform: translate(-100%, -100%);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    border: 1px;
    border-color: var(--border-subdued);

    > button {
      height: 32px;
      padding: var(--spacing-100) var(--spacing-200);
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
      font-weight: 400;
      justify-content: flex-start;
    }
  }
}
