.container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-left: clamp(var(--spacing-400), 5vw, var(--spacing-900));
  padding-right: clamp(var(--spacing-400), 5vw, var(--spacing-900));

  &.has-bg-image {
    z-index: 2;
  }
}

.section {
  position: relative;
  overflow: hidden;
  color: var(--text-default);
  padding-top: var(--spacing-600);
  padding-bottom: var(--spacing-600);
  z-index: 0;

  @include from($widthDesktopLarge) {
    padding-top: var(--spacing-800);
    padding-bottom: var(--spacing-800);
  }

  &.is-sandwished {
    padding-top: var(--spacing-400);
    padding-bottom: var(--spacing-400);
  }

  &.is-dark {
    background-color: var(--bg-layer);
  }

  &.is-green {
    background: linear-gradient(to right, rgba(#22f28e, 0.11), rgba(#47fac1, 0.11));
  }

  .title {
    font-weight: bold;
  }

  .subtitle {
    font-weight: 300;
    font-size: var(--text-xs-font-size);
    margin-bottom: var(--spacing-400);
    color: var(--text-default);
  }
}

.columns {
  display: flex;
  flex-flow: wrap;
  margin-left: calc(var(--spacing-400) * -1);
  margin-right: calc(var(--spacing-400) * -1);

  .column {
    width: 100%;
    padding: var(--spacing-400);

    &.is-half {
      @include from($widthMobile) {
        width: 50%;
      }
    }

    &.is-one-third {
      @include from($widthMobile) {
        width: 50%;
      }
      @include from($widthTablet) {
        width: 33.33%;
      }
    }
  }
}

// CARD

.card {
  height: 100%;
  transition: all var(--transition-speed);
  border: 1px solid #bfcace;
  padding: var(--spacing-400);
  border-radius: var(--radius-md);
  background-color: var(--white);
}

// MODAL

.modal {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: color-mix(in srgb, var(--blue-800) 10%, transparent);
  overflow: auto;

  @include from($widthMobile) {
    overflow: hidden;
  }

  form {
    input,
    .checkgroup {
      margin-bottom: var(--spacing-400);
    }

    .checkgroup {
      align-content: center;

      span {
        margin-right: var(--spacing-500);
      }
    }
  }
}

.modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: calc(var(--spacing-200) + var(--spacing-400)) var(--spacing-200) var(--spacing-200);

  @include from($widthMobile) {
    align-items: center;
    height: 100%;
    padding: 0;
  }
}

.modal-content {
  background-color: #fefefe;
  padding: var(--spacing-500);
  width: 100%;
  max-width: 700px;
  position: relative;
  border-radius: 0 0 var(--radius-sm) var(--radius-sm);
  box-shadow: var(--shadow-lg);

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    height: 15px;
    background-color: var(--gradient-green);
    border-radius: var(--radius-sm) var(--radius-sm) 0 0;
  }
}

// VIDEO / IMAGES

.video-16-9 {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// TOOLTIP

.tooltip {
  max-width: 480px;
  font-size: var(--text-xs-font-size);
  line-height: var(--text-xs-line-height);
  z-index: 9;
}

// OVERLAY
// = being used to disable hover state of all underlying elements when dropdown is open for UX purposes

.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: #000;
  opacity: 0.5;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    z-index: 0;
    opacity: 0;
  }
  to {
    opacity: 0.5;
    z-index: 100;
  }
}
