@import 'styles/breakpoints';

.stay-informed {
  .form-group {
    width: 100%;
    margin-bottom: 0;
    gap: var(--spacing-400);

    @include from($widthMobile) {
      display: flex;
      gap: var(--spacing-400);

      .form-input {
        width: 50%;
      }
    }

    @include from($widthTablet) {
      display: block;

      .form-input {
        width: 100%;

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }

    @include from($widthDesktop) {
      display: flex;
      gap: var(--spacing-400);

      .form-input {
        width: 50%;
      }
    }
  }

  .form-input {
    width: 100%;

    .input {
      display: block;
      width: 100%;
    }
  }

  label {
    display: block;
    font-weight: normal;
  }
}
