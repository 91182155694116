// REACT SLICK SLIDER STYLES
// should be global styles because they overwrite styling provided by react-slick package,
// and can't be imported from a CSS module

.slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.community-metrics-slider {
  .slick-list {
    height: auto;
  }
}

.slick-arrow {
  display: none !important;
}

.slick-track {
  display: flex !important;

  .slick-slide {
    height: auto !important;

    & > div {
      position: relative;
      height: 100%;
    }
  }
}

.slick-dots {
  margin-top: var(--spacing-200);
  text-align: center;

  & > li {
    display: inline-block;
    margin-right: 7px;

    &:last-child {
      margin-right: 0;
    }

    a {
      cursor: pointer;
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: var(--blue-800);
      opacity: 0.15;

      @include until($widthMobile) {
        width: 8px;
        height: 8px;
      }
    }

    &.slick-active {
      a {
        opacity: 1;
      }
    }
  }
}
