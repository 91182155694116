@import 'styles/breakpoints';

.drawer {
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }

  position: fixed;
  top: 0;
  right: 0;
  width: 620px;
  z-index: 110;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @include until($widthTablet) {
    width: 100%;
    height: auto;
    max-height: 100vh;
    @supports (height: 100dvh) {
      max-height: 100dvh;
    }

    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }

  .drawer-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px 40px;
    height: 71px;
    border-bottom: 1px solid var(--neutral-300);
    background-color: var(--white);

    @include until($widthTablet) {
      margin-top: var(--spacing-500);
      border-top-left-radius: var(--spacing-500);
      border-top-right-radius: var(--spacing-500);

      &.with-image {
        overflow: hidden;
      }
    }

    &.with-extra {
      justify-content: space-between;
    }

    &.with-image {
      height: 240px;
      flex-shrink: 0;

      .header-img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        object-fit: cover;
      }

      .drawer-close {
        position: fixed;
      }
    }

    @include until($widthTablet) {
      padding: 20px var(--spacing-400);
    }

    .drawer-close {
      position: relative;
      z-index: 5;
      color: var(--neutral-400);
      cursor: pointer;
      width: 24px;
      height: 24px;

      @include until($widthTablet) {
        background-color: #e7e8f0;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        svg {
          color: #abaec8;
        }
      }
    }
  }
}

.drawer-content {
  flex-grow: 1;
  padding: var(--spacing-600);
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  @include until($widthTablet) {
    padding: var(--spacing-600) var(--spacing-400);
  }
}
