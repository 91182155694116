@import 'styles/breakpoints';

.banner {
  position: sticky;
  top: 70px;
  z-index: 6;
  font-weight: bold;
  padding: var(--spacing-300) 0;
  width: 100%;
  transition: all var(--transition-speed);

  &:not(.no-pointer):hover {
    cursor: pointer;
  }

  &.is-yellow {
    background-color: var(--accent-yellow);
    color: var(--text-default);
  }

  &.scrolling-down {
    top: 0;
  }

  p {
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);

    @include until($widthMobile) {
      font-size: var(--text-sm-font-size);
      line-height: var(--text-sm-line-height);
    }
  }

  .predefined-banner {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);

    small {
      font-weight: 400;
    }

    .font-md {
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
    }
  }
}
