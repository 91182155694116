@import 'styles/breakpoints';

.language-switcher {
  display: flex;
  flex-direction: row;

  li {
    display: inline-flex;
    gap: var(--spacing-200);

    button {
      color: var(--text-default);

      &.is-active {
        font-weight: bold;
      }

      &:not([disabled]) {
        cursor: pointer;

        &:hover {
          color: var(--text-primary-hover);
        }
      }

      &[disabled] {
        cursor: default;
      }
    }
  }
}
